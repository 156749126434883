<template>
  <div>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("articles-overview.articles-overview") }}
      </v-card-title>
      <v-card-text>
        <v-card-subtitle>
          <p>
            Hieronder vind je een overzicht van de aangeboden abonnementsvormen
            en tarieven.
          </p>
          <h2 style="font-size: 16px">
            Belangrijk bij het invullen van dit tarievenformulier:
          </h2>
          <p>
            Er zijn een aantal zaken waar je rekening mee moet houden bij het invullen van het tarievenformulier.
            Hieronder vind je de belangrijkste punten. Wil je ook de overige aandachtspunten inzien? 
            <a href="https://www.bedrijfsfitnessnederland.nl/media/ylpbffsp/tarievenformulier-info-2024-03-002.pdf" target="_blank" rel="noopener noreferrer">Klik dan hier</a>.
            </p>
          <ul>
            <li>
              Met dit tarievenformulier geef je een prijswijziging door voor het
              actuele abonnementsaanbod.
            </li>
            <li>
              Vermeld het tarief zoals dat ook regulier/particulier geldt bij de betreffende abonnementsvorm (qua aanbod, intensiteit en duur).
            </li>
            <li>
              Heb je meerdere locaties? Geef de tariefswijziging dan per locatie aan ons door. 
            </li>
          </ul>
        </v-card-subtitle>
        <v-alert
          outlined
          type="info"
          prominent
          border="left"
          class="mx-4"
          v-if="state.matches('loadingarticles') || state.matches('loading')"
        >
          {{ $t("generic.loading-moment") }}
        </v-alert>
        <v-alert
          outlined
          type="success"
          prominent
          border="left"
          class="mx-4"
          v-else-if="state.matches('success')"
        >
          {{ $t("sportlocation.financial-success") }}
        </v-alert>
        <v-alert
          outlined
          type="info"
          prominent
          border="left"
          v-else-if="state.matches('blocked')"
          class="mx-4"
        >
          {{
            $t("sportlocation.blocked")
          }}
          <br />
          Je hebt de volgende tariefswijziging doorgevoerd:

          <div
            v-for="(val, index) in pendingArticlesByTimesPerWeek"
            :key="index"
            class="collection"
          >
            <div>
              <div class="collection-header">
                <h3 class="collection-title">
                  <div v-if="val[0] === null">Intake</div>
                  <div v-else-if="val[0] === 0">Onbeperkt</div>
                  <div v-else>{{ val[0] }}x per week</div>
                </h3>
                <div class="current-price-header">Huidig</div>
                <div class="new-price-header">Nieuw</div>
              </div>

              <v-divider></v-divider>
              <div class="articles">
                <div
                  v-for="article in val[1]"
                  :key="article.articleCode ?? ''"
                  class="article"
                >
                  <div class="article__input">
                    <div class="description">
                      <div class="description-text">
                        <span v-if="article.months">
                          {{ article.months }} maanden -
                          {{ article.articleCode }}
                        </span>
                        {{ article.description }}
                      </div>
                      <div class="explanation">
                        {{ article.explanation }}
                      </div>
                      <div class="netto-price" v-if="article.nettoPrice">
                        Deze prijs is inclusief een aanvullend abonnement voor
                        extra faciliteiten van
                        {{ formatCurrency(article.nettoPrice) }} p/m.
                      </div>
                    </div>
                    <div
                      class="price"
                      v-if="article.price || article.price === 0"
                    >
                      {{ formatCurrency(article.price) }}
                    </div>
                    <div v-if="article.isIntake" class="payment">eenmalig</div>
                    <div v-else class="payment">per maand</div>
                    <div
                      class="price"
                    >
                      {{ formatCurrency(article.priceNew ?? 0) }}
                    </div>
                    <div v-if="article.isIntake">eenmalig</div>
                    <div v-else>per maand</div>
                    <!-- <div class="pl-2">
                      <template v-if="article.vatPercentage">
                        inclusief {{ formatPercentage(article.vatPercentage) }}% BTW
                      </template>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          Klopt deze tariefswijziging niet? Dan kan je de wijziging annuleren via de onderstaande knop.
          <div class="mt-4 mb-4">
            <v-dialog
              v-model="showDeleteDialog"
              persistent
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" outlined v-bind="attrs" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                  Annuleren
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Weet je het zeker?</v-card-title>
                <v-card-text
                  >Weet je zeker dat je de tariefswijziging wilt annuleren?</v-card-text
                >
                <v-card-actions>
                  <v-btn color="" text @click="showDeleteDialog = false">
                    Nee
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="deletePendingArticles">
                    Ja
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          Voor vragen kan je contact opnemen met onze
          <router-link :to="{ name: 'contact' }">klantenservice</router-link>.


        </v-alert>

        <v-form
          v-if="
            !state.matches('success') &&
            !state.matches('loadingarticles') &&
            !state.matches('blocked')
          "
          v-model="formIsValid"
          @submit.prevent="service.send('UPDATEARTICLES')"
          ref="form"
        >
          <div class="clearfix">
            <label>
              Ingangsdatum
              <span class="required">*</span>
            </label>
            <div class="input">
              <v-menu
                ref="menu"
                v-model="isDateMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[(v) => !!v || 'Ingangsdatum is verplicht']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="onDateMenuSave"
                  :min="startDate"
                  :first-day-of-week="1"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div
            v-for="(val, index) in articlesByTimesPerWeek"
            :key="index"
            class="collection"
          >
            <div>
              <div class="collection-header">
                <h3 class="collection-title">
                  <div v-if="val[0] === null">Intake</div>
                  <div v-else-if="val[0] === 0">Onbeperkt</div>
                  <div v-else>{{ val[0] }}x per week</div>
                </h3>
                <div class="current-price-header">Huidig</div>
                <div class="new-price-header">Nieuw</div>
              </div>

              <v-divider></v-divider>
              <div class="articles">
                <div
                  v-for="article in val[1]"
                  :key="article.articleCode ?? ''"
                  class="article"
                >
                  <div class="article__input">
                    <div class="description">
                      <div class="description-text">
                        <span v-if="article.months">
                          {{ article.months }} maanden -
                          {{ article.articleCode }}
                        </span>
                        {{ article.description }}
                      </div>
                      <div class="explanation">
                        {{ article.explanation }}
                      </div>
                      <div class="netto-price" v-if="article.nettoPrice">
                        Deze prijs is inclusief een aanvullend abonnement voor
                        extra faciliteiten van
                        {{ formatCurrency(article.nettoPrice) }} p/m.
                      </div>
                    </div>
                    <div
                      class="price"
                      v-if="article.price || article.price === 0"
                    >
                      {{ formatCurrency(article.price) }}
                    </div>
                    <div v-if="article.isIntake" class="payment">eenmalig</div>
                    <div v-else class="payment">per maand</div>
                    <div>€</div>
                    <v-text-field
                      @change="articleChange(article)"
                      hide-details
                      required
                      dense
                      v-model="article.priceNew"
                      type="text"
                      class="new-price"
                      placeholder="Nieuwe prijs"
                      :rules="[
                        (v) =>
                          (val[0] === null &&
                            parseFloat(String(v).replace(',', '.')) >= 0) ||
                          (val[0] !== null && val[0] >= 0 &&
                            v !== null &&
                            v !== undefined &&
                            parseFloat(String(v).replace(',', '.')) > 0) ||
                          `Prijs is verplicht`,
                        (v) =>
                          /^([\u20AC]?[0-9]\d*d{3}(?:,\d{2})?|[\u20AC]?[0-9]\d*(?:,\d{2})?|[\u20AC]?[0-9]\d*)$/.test(
                            String(v)
                          ) || `Voer een geldig bedrag in`,
                      ]"
                    />
                    <div v-if="article.isIntake">eenmalig</div>
                    <div v-else>per maand</div>
                    <div class="pl-2">
                      <template v-if="article.vatPercentage">
                        inclusief {{ formatPercentage(article.vatPercentage) }}% BTW
                      </template>
                    </div>
                  </div>
                  <div v-if="showIncreaseReason && article.isIntake ">
                    <div class="description"><div class="description-text">Reden verhoging: </div></div>
                    <v-textarea
                        required
                        rows="3"
                        v-model="article.increaseReason"
                        auto-grow
                        filled
                        :rules="[
                          (v) =>
                            !!v ||
                            'Geef een reden op voor het verhogen van de intake prijs',
                          ]">
                      </v-textarea>
                  </div>
                  <div class="article__message">
                    {{ articleStatusMessage(article.articleCode) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="archivedArticlesByTimesPerWeek?.size">
            <v-toolbar-title class="headline primary--text">
              {{ $t("articles-overview.articles-overview-archived") }}
            </v-toolbar-title>
            <v-card-subtitle>
              Hieronder vind je een overzicht van oude abonnementen/tarieven die nog in gebruik zijn.
              Deze abonnementen worden niet actief aangeboden aan onze klanten.
            </v-card-subtitle>
            <div
              v-for="(val, index) in archivedArticlesByTimesPerWeek"
              :key="index"
              class="collection"
            >
              <div>
                <div class="collection-header">
                  <h3 class="collection-title">
                    <div v-if="val[0] === null">Intake</div>
                    <div v-else-if="val[0] === 0">Onbeperkt</div>
                    <div v-else>{{ val[0] }}x per week</div>
                  </h3>
                  <div class="current-price-header">Huidig</div>
                  <div class="new-price-header">Nieuw</div>
                </div>

                <v-divider></v-divider>
                <div class="articles">
                  <div
                    v-for="article in val[1]"
                    :key="article.articleCode ?? ''"
                    class="article"
                  >
                    <div class="article__input">
                      <div class="description">
                        <div class="description-text">
                          <span v-if="article.months">
                            {{ article.months }} maanden -
                            {{ article.articleCode }}
                          </span>
                          {{ article.description }}
                        </div>
                        <div class="explanation">
                          {{ article.explanation }}
                        </div>
                        <div class="netto-price" v-if="article.nettoPrice">
                          Deze prijs is inclusief een aanvullend abonnement voor
                          extra faciliteiten van
                          {{ formatCurrency(article.nettoPrice) }} p/m.
                        </div>
                      </div>
                      <div
                        class="price"
                        v-if="article.price || article.price === 0"
                      >
                        {{ formatCurrency(article.price) }}
                      </div>
                      <div v-if="article.isIntake" class="payment">eenmalig</div>
                      <div v-else class="payment">per maand</div>
                      <div>€</div>
                      <v-text-field
                        hide-details
                        required
                        dense
                        v-model="article.priceNew"
                        type="text"
                        class="new-price"
                        placeholder="Nieuwe prijs"
                        :rules="[
                          (v) =>
                            (val[0] === null &&
                              parseFloat(String(v).replace(',', '.')) >= 0) ||
                            (val[0] !== null && val[0] >= 0 &&
                              v !== null &&
                              v !== undefined &&
                              parseFloat(String(v).replace(',', '.')) > 0) ||
                            `Prijs is verplicht`,
                          (v) =>
                            /^([\u20AC]?[0-9]\d*d{3}(?:,\d{2})?|[\u20AC]?[0-9]\d*(?:,\d{2})?|[\u20AC]?[0-9]\d*)$/.test(
                              String(v)
                            ) || `Voer een geldig bedrag in`,
                        ]"
                      />
                      <div v-if="article.isIntake">eenmalig</div>
                      <div v-else>per maand</div>
                      <div class="pl-2">
                        <template v-if="article.vatPercentage">
                          inclusief {{ formatPercentage(article.vatPercentage) }}% BTW
                        </template>
                      </div>
                    </div>
                    <div class="article__message">
                      {{ articleStatusMessage(article.articleCode) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </div>

          <v-checkbox
            v-if="hasArticleStatuses"
            v-model="acceptTermsValidation"
            required
            hide-details
            :rules="[
              (v) =>
                !!v ||
                'Accepteer de voorwaarden voordat het formulier verstuurd kan worden',
            ]"
            label="Ik bevestig de ingevoerde prijzen en ben bewust dat deze wijzigingen handmatig worden doorgevoerd."
          ></v-checkbox>
          <v-checkbox
            required
            v-model="acceptTerms"
            :rules="[
              (v) =>
                !!v ||
                'Accepteer de voorwaarden voordat het formulier verstuurd kan worden',
            ]"
            :label="`Ik bevestig dat de ingevoerde prijzen inclusief ${vatPercentageForText} BTW zijn conform de reguliere tarieven van mijn sportlocatie`"
          ></v-checkbox>
          <div class="clearfix mt-4">
            <v-btn class="mr-4">
              <router-link
                style="text-decoration: none; color: inherit"
                :to="{ name: 'articles-overview' }"
              >
                {{ $t("edit-price-articles.cancel") }}
              </router-link>
            </v-btn>
            <v-btn
              type="submit"
              :disabled="
                !formIsValid ||
                state.matches('loadingarticles') ||
                state.matches('loading') ||
                state.matches('blocked')
              "
              class="success"
            >
              {{ $t("edit-price-articles.send") }}
            </v-btn>
          </div>
        </v-form>
        <br />
        <div>
          <h3>Extra abonnementsvormen</h3>
          <p>
            Bedrijfsfitness Nederland stimuleert het aanbieden van verschillende
            abonnementsvormen voor bedrijfsfitness. Naast abonnementen voor
            fitness en/of groepslessen zijn ook abonnementsvormen met extra
            faciliteiten mogelijk (bv. persoonlijke begeleiding, squash, budo,
            sauna/wellness etc.). We sluiten hierbij zoveel mogelijk aan op de
            diversiteit zoals jouw sportlocatie die regulier biedt.
          </p>
          <p>
            Wil je aan bovenstaand aanbod abonnementsvormen toevoegen (of laten
            vervallen), neem hiervoor dan contact op met onze
            <router-link :to="{ name: 'contact' }">klantenservice</router-link>.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {
  ArticleApi,
  ArticleDto2,
  PendingArticleDto,
  PendingArticlesDto,
  UpdateArticleDto,
  ValidateResultArticleDto,
  ValidateResultStatus,
  ValidateUpdateArticlesQuery,
} from "@/api";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  Ref
}from "vue"
import { useMachine } from "xstate-vue2";

import { useSnackbarStore } from '@/store/snackbar';
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import { formatCurrency } from "@/util/currency";
import { formatPercentage } from "@/util/percentage";
import { VMenu } from "vuetify/lib";
import updateArticlesMachine from "./UpdateArticlesMachine";

interface ArticleDtoWithExtraFields extends ArticleDto2 {
  priceNew: number | undefined;
  increaseReason: string | null;
}

const Component = defineComponent({
  name: "UpdateArticles",
  components: {},
  setup() {
    const root = getCurrentInstance()?.proxy;

    const snackbarStore = useSnackbarStore();
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, send, service } = useMachine(updateArticlesMachine);
    const api = new ArticleApi();

    const formIsValid = ref(false);
    const form: Ref<{ validate: Function } | null> = ref(null);
    const isDateMenuOpen = ref(false);

    const pendingArticles = ref<PendingArticlesDto>();
    const pendingArticlesByTimesPerWeek = ref<Map<number | null, PendingArticleDto[]>>();
    const pendingArchivedArticlesByTimesPerWeek = ref<Map<number | null, PendingArticleDto[]>>();

    const articlesByTimesPerWeek = ref<Map<number | null, ArticleDtoWithExtraFields[]>>();
    const archivedArticlesByTimesPerWeek = ref<Map<number | null, ArticleDtoWithExtraFields[]>>();
    const vatPercentageForText = ref("");

    const date = ref("");
    const startDate = ref("");
    const acceptTerms = ref(false);
    const showTermsAlert = ref(false);
    const nextChangeDate = ref("");
    const articleStatuses = ref<
      ValidateResultArticleDto[] | null | undefined
    >();
    const acceptTermsValidation = ref(false);
    const showDeleteDialog = ref(false);

    interface VMenuExtended extends InstanceType<typeof VMenu> {
      save: (value: string) => void;
    }

    const menu: Ref<VMenuExtended | null> = ref(null);

    const hasArticleStatuses = computed(
      () => articleStatuses.value && articleStatuses.value.length > 0
    );
    
    const showIncreaseReason = ref<boolean>(false);


    function articleChange(article: ArticleDtoWithExtraFields): void{

      if(!article.isIntake || article.price === null || article.price === undefined)
      {
        return
      }
      const newPrice = Number(article.priceNew);
      if((article.price === 0 && newPrice > 0) || (newPrice / article.price) * 100 > 120)
      {
        showIncreaseReason.value = true;
        return
      }
      article.increaseReason = "";
      showIncreaseReason.value = false;
    }
    
    async function getStartDate() {
      const { data } = await api.articleGetArticleEditStartDate();
      // Only use date part.
      startDate.value = data.substring(0, 10);
      if (!date.value) {
        date.value = data.substring(0, 10);
      }
    }

    function onDateMenuSave(): void {
      if (!menu.value) return;
      menu.value.save(date.value);
    }

    async function checkPendingArticlePriceChanges(sportlocationId: number) {
      pendingArticles.value = undefined;

      await api.articleGetPendingArticlesUpdate(sportlocationId).then((data) => {
        pendingArticles.value = data.data;
        pendingArticlesByTimesPerWeek.value = getPendingArticlesByTimesPerWeek(pendingArticles.value.articles || []);
        pendingArchivedArticlesByTimesPerWeek.value = getPendingArticlesByTimesPerWeek(pendingArticles.value.archivedArticles || []);

        const datePriceChange = new Date(pendingArticles.value.datePriceChange || "");

        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        } as Intl.DateTimeFormatOptions;
        nextChangeDate.value = datePriceChange.toLocaleDateString(
          "nl-NL",
          options
        );

        if (datePriceChange > new Date()) {
          service.send("BLOCK");
        }
      });
    }

    function getArticlesByTimesPerWeek(articles: ArticleDtoWithExtraFields[]) {
      const byTimesPerWeek = new Map<number | null, ArticleDtoWithExtraFields[]>();
      articles.forEach(article => {
        if (byTimesPerWeek.has(article.timesPerWeek ?? null)) {
          byTimesPerWeek.get(article.timesPerWeek ?? null)?.push(article);
        }
        else {
          byTimesPerWeek.set(article.timesPerWeek ?? null, new Array(article));
        }
      });
      return byTimesPerWeek;
    }

    function getPendingArticlesByTimesPerWeek(articles: PendingArticleDto[]) {
      const byTimesPerWeek = new Map<number | null, PendingArticleDto[]>();
      articles.forEach(article => {
        if (byTimesPerWeek.has(article.timesPerWeek ?? null)) {
          byTimesPerWeek.get(article.timesPerWeek ?? null)?.push(article);
        }
        else {
          byTimesPerWeek.set(article.timesPerWeek ?? null, new Array(article));
        }
      });
      return byTimesPerWeek;
    }

    async function getArticlesOverview() {
      service.send("LOADARTICLES");

      const sportLocationId = user.value.selectedSportLocation?.sportLocationId ?? 0
      try {
        const { data } = await api.articleGetArticles(sportLocationId);

        const articles : ArticleDtoWithExtraFields[] = (data.articles ?? []).map((a) => {
          return a as ArticleDtoWithExtraFields;
        });

        articlesByTimesPerWeek.value = getArticlesByTimesPerWeek(articles);

        const archivedArticles : ArticleDtoWithExtraFields[] = (data.archivedArticles ?? []).map((a) => {
          return a as ArticleDtoWithExtraFields;
        });
        archivedArticlesByTimesPerWeek.value = getArticlesByTimesPerWeek(archivedArticles);

        vatPercentageForText.value = data.vatPercentageText ?? '';

        // Get start date here, so we still have the loading screen.
        await getStartDate();

        await checkPendingArticlePriceChanges(sportLocationId);
        if (!state.value.matches("blocked")) {
          service.send("RESOLVE");
        }
      } catch {
        service.send("REJECT");
      }
    }

    async function validateUpdateArticles() {
      const allArticles : ArticleDtoWithExtraFields[]= new Array();
      articlesByTimesPerWeek.value?.forEach(element => {
        allArticles.push(...element);
      });
      archivedArticlesByTimesPerWeek.value?.forEach(element => {
        allArticles.push(...element);
      });

      const mappedValues: UpdateArticleDto[] = allArticles.map(
        (a) => {
          let decimal = 0;
          if (a.priceNew) {
            const asString = `${a.priceNew}`;
            const removeDots = asString.replace(",", ".");
            decimal = parseFloat(removeDots);
          }
          return {
            articleCode: a.articleCode,
            priceNew: a.priceNew ? decimal : undefined,
            increaseReason: a.increaseReason
          } as UpdateArticleDto;
        }
      );

      const sportLocationId = user.value.selectedSportLocation?.sportLocationId ?? 0
      try {
        const query: ValidateUpdateArticlesQuery = {
          sportLocationId,
          liveDate: date.value,
          articles: mappedValues
        };
        const { data } = await api.articleValidateUpdateArticles(query);
        articleStatuses.value = data.articleStatuses;

        // Invalid input
        if (data.status === ValidateResultStatus.Invalid) {
          service.send("REJECT");
        }

        // Just Process
        else if (
          data.status === ValidateResultStatus.ValidAutomatic ||
          data.status === ValidateResultStatus.ValidManual
        ) {
          await api.articleUpdateArticles({
            validateUpdateArticlesQuery: query,
          });

          service.send("RESOLVE");
        }
        // Ignore this, for now. No manual accept of terms, just process in the logic above.
        /*
        // Manual validation & Accepted these terms
        else if (
          data.status === ValidateResultStatus.ValidManual &&
          acceptTermsValidation.value
        ) {
          api.articleUpdateArticles({
            validateUpdateArticlesQuery: query,
          });
          service.send("RESOLVE");
        }

        // Manual validation - Show messages and ask confirmation.
        else if (data.status === ValidateResultStatus.ValidManual) {
          service.send("REJECT");
        }
        */
      } catch {
        snackbarStore.setMessage("Interne fout: Het is ons niet gelukt om de prijs bij te werken.", "error");
        service.send("REJECT");
      }
    }

    function articleStatusMessage(articleCode?: string | null): string {
      const found = articleStatuses.value?.find(
        (a) => a.articleCode === articleCode
      );
      return found?.errorMessage ?? "";
    }

    async function deletePendingArticles() {
      showDeleteDialog.value = false;

      try {
        const sportLocationId = user.value.selectedSportLocation?.sportLocationId ?? 0

        await api.articleDeletePendingArticleUpdate({
          sportLocationId
        });

        snackbarStore.setMessage("De tariefswijziging is verwijderd.");
        getArticlesOverview();
      } catch {
        snackbarStore.setMessage("De tariefswijziging is niet verwijderd. Probeer het nogmaals.", "error");
        getArticlesOverview();
      }
    }

    onMounted(async () => {
      getArticlesOverview();

      service.subscribe((s) => {
        if (s.matches("loading")) {
          validateUpdateArticles();
        }
      });
    });

    return {
      acceptTerms,
      acceptTermsValidation,
      articleStatuses,
      articlesByTimesPerWeek,
      archivedArticlesByTimesPerWeek,
      date,
      form,
      formIsValid,
      hasArticleStatuses,
      isDateMenuOpen,
      menu,
      nextChangeDate,
      service,
      showTermsAlert,
      startDate,
      state,
      formatCurrency,
      formatPercentage,
      articleStatusMessage,
      onDateMenuSave,
      send,
      articleChange,
      showIncreaseReason,
      vatPercentageForText,
      pendingArticlesByTimesPerWeek,
      pendingArchivedArticlesByTimesPerWeek,
      showDeleteDialog,
      deletePendingArticles,
    };
  },
});
export default Component;
</script>

<style lang="scss" scoped>
.v-card__text {
  max-width: 960px;
}
.v-card__subtitle {
  padding-left: 0;
}
.collection {
  margin-bottom: 2rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.articles {
  margin-left: 0.5rem;
}

.collection-header {
  display: flex;
  margin-top: 0.5rem;
  .collection-title {
    font-weight: bold;
    margin-right: 1rem;
    flex: 1 1 50%;
    flex-direction: column;
  }
  .current-price-header {
    font-weight: bold;
    flex: 1 1 120px;
    margin: 0 1rem 0 auto;
    text-align: left;
  }
  .new-price-header {
    font-weight: bold;
    flex: 1 1 80px;
    margin: 0 1rem 0 auto;
    text-align: left;
  }
}
.article {
  margin-top: 0.5rem;

  &__input {
    display: flex;
  }

  &__message {
    margin-bottom: 1.5rem;
    color: red;
  }

  .description {
    margin-right: 1rem;
    flex: 1 1 50%;
    flex-direction: column;
    .description-text {
      font-weight: bold;
    }
    .explanation {
      font-style: italic;
      max-width: 80%;
    }
  }
  .netto-price {
    margin-top: 1rem;
  }
  .price {
    flex: 0 0 75px;
    margin: 0 1rem 0 auto;
    text-align: right;
  }
  .payment {
    flex: 1 1 100px;
  }
}
.rate-change {
  padding-top: 1rem;
  p {
    margin-top: 0.5rem;
  }
  a {
    text-decoration: underline;
  }
}
.clearfix {
  margin: 0 0 10px 0;
  position: relative;
}
.clearfix > label {
  display: inline-block;
}
.clearfix > .input {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: sub;
}
.new-price {
  max-width: 90px;
  padding: 1px;
  margin: -4px 4px 0 3px;
  font-size: 14px;
}
</style>
