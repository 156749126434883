<template>
  <div v-if="selectedSportLocationInfo">
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.general") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.sportlocationnumber") }}</td>
              <td>{{ selectedSportLocationInfo.sportLocationNumber }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.name") }}</td>
              <td>{{ selectedSportLocationInfo.name }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.address") }}</td>
              <td>{{ selectedSportLocationInfo.address }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.postalcode-city") }}</td>
              <td>
                {{ selectedSportLocationInfo.postalCode }}
                {{ selectedSportLocationInfo.city }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.website") }}</td>
              <td>{{ selectedSportLocationInfo.website }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.phonenumber") }}</td>
              <td>{{ selectedSportLocationInfo.phoneNumber }}</td>
            </tr>
            <tr v-if="selectedSportLocationInfo.sportLocationAskProfile">
              <td></td>
              <td>
                <router-link :to="{ name: 'update-sportlocation-generalinfo'}">
                  {{ $t("sportlocation.info-update") }}
                </router-link >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.logo") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.logo") }}</td>
              <td>
                <img
                  v-if="selectedSportLocationInfo.logoUri"
                  :src="selectedSportLocationInfo.logoUri"
                  alt="Logo"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <router-link :to="{ name: 'update-sportlocation-logo' }">
                  {{ $t("sportlocation.logo-update") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.contactperson") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.contactperson-name") }}</td>
              <td>{{ selectedSportLocationInfo.contactPersonName }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.contactperson-emailaddresses") }}</td>
              <td>
                {{ selectedSportLocationInfo.contactPersonEmailAddresses }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <router-link
                  :to="{ name: 'update-sportlocation-contactperson' }"
                >
                  {{ $t("sportlocation.contactperson-update") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.financial") }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td width="20%">{{ $t("sportlocation.financial-iban") }}</td>
              <td>{{ selectedSportLocationInfo.iban }}</td>
            </tr>
            <tr>
              <td>{{ $t("sportlocation.financial-vatnumber") }}</td>
              <td>{{ selectedSportLocationInfo.vatNumber }}</td>
            </tr>
            <tr>
              <td>
                {{ $t("sportlocation.financial-chamberofcommercenumber") }}
              </td>
              <td>{{ selectedSportLocationInfo.chamberOfCommerceNumber }}</td>
            </tr>
            <tr>
              <td colspan="2">
                In het geval het IBAN-nummer gewijzigd moet worden kan dit door
                <span class="font-weight-bold">sportlocaties@bfnl.nl</span> hier
                opdracht toe te geven met akkoord van een tekenbevoegd persoon.
                <span class="text-decoration-underline font-weight-bold"
                  >Hiervoor zijn uittreksel KVK en een kopie ID van tekenbevoegd
                  persoon benodigd.</span
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.payment") }}
      </v-card-title>
      <v-card-text>
        <ul>
          <template
            v-if="selectedSportLocationInfo.paymentConditionCode === '00'"
          >
            <li>Vergoeding vindt plaats op basis van vooruitbetaling.</li>
          </template>
          <template v-else>
            <li>
              Betaling vindt plaats
              {{ selectedSportLocationInfo.paymentConditionCode }}
              na ingangsdatum abonnementsperiode.
            </li>
          </template>

          <template
            v-if="selectedSportLocationInfo.payFirstApplicationInWhole === true"
          >
            <li>Een aanmelding wordt in één keer betaald.</li>
          </template>
          <template v-else>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 0">
              Een aanmelding wordt in één keer betaald.
            </li>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 4">
              Een aanmelding wordt per 3 maanden betaald gerekend vanaf
              ingangsdatum.
            </li>
            <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 12">
              Een aanmelding wordt per maand betaald gerekend vanaf
              ingangsdatum.
            </li>
          </template>

          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 0">
            Een verlenging wordt in één keer betaald.
          </li>
          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 4">
            Een verlenging wordt per 3 maanden betaald gerekend vanaf
            ingangsdatum verlenging.
          </li>
          <li v-if="selectedSportLocationInfo.payInNumberOfTerms === 12">
            Een verlenging wordt per maand betaald gerekend vanaf ingangsdatum
            verlenging.
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <v-card class="mb-5" v-if="portalFiles && portalFiles.length">
      <v-card-title class="headline primary--text">
        {{ $t("sportlocation.documents") }}
      </v-card-title>

      <v-card-text>
        <div class="portalfiles">
          <v-card outlined rounded="3" elevation="0" class="portalfiles__item" v-for="(portalFile, index) in portalFiles" :key="index">
            <v-card-title class="portalfiles__item__title flex-nowrap">
                <v-icon large class="mr-1">
                  {{getIconByMimeType(portalFile.mimeType)}}
                </v-icon>
                <div>{{ portalFile.fileName }}</div>
            </v-card-title>
            <v-card-text>  
              {{ bytesToSize(portalFile.fileSize ?? 0) }} <br />
              {{ toLocalDateTimeString(portalFile.dateAdded) }} <br />
            </v-card-text>
            <v-card-actions>
              <v-btn @click.prevent="onDownloadPortalFile(portalFile.fileName || '')" small depressed>
                <v-icon>mdi-download</v-icon>
                {{ $t("generic.download") }}
              </v-btn> 
            </v-card-actions>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
}from "vue"
import { useMachine } from "xstate-vue2";

import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'

import { PortalFileDto, PortalFilesApi, SportLocationApi, SportLocationInfoDto } from "@/api";
import sportLocationInfoMachine from "./SportLocationInfoMachine";
import { downloadDocument } from "@/util/download";

import { toLocalDateTimeString } from "@/util/date";
import bytesToSize from "@/util/file";

const Component = defineComponent({
  name: "SportLocationInfo",
  setup() {
    const root = getCurrentInstance()?.proxy;

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const { state, send, service } = useMachine(sportLocationInfoMachine);
    const sportLocationApi = new SportLocationApi();
    const portalFilesApi = new PortalFilesApi();

    const selectedSportLocationId = computed<number | null>(
      () => user.value.selectedSportLocation?.sportLocationId ?? 0,
    );
    const selectedSportLocationInfo = computed<SportLocationInfoDto | null>(
      () => user.value.selectedSportLocationInfo ?? null,
    );

    const portalFiles = ref<PortalFileDto[]>([]);

    async function fetchSportLocationInfo() {
      service.send("RELOAD");

      if (!selectedSportLocationId.value) {
        service.send("REJECT");
        return;
      }
      try {
        const {
          data,
        } = await sportLocationApi.sportLocationGetSportLocationInfo(
          selectedSportLocationId.value
          );
        userStore.setSportLocationInfo(data);
        service.send("RESOLVE");
      } catch {
        service.send("REJECT");
      }
    }

    async function fetchPortalFiles() {
      if (!selectedSportLocationId.value){
        return;
      }
      
      try {
        const { data } = await portalFilesApi.portalFilesGetPortalFiles(selectedSportLocationId.value);
        portalFiles.value = data;
      }
      catch {
        return;
      }
    }

    async function onDownloadPortalFile(fileName: string) {
      if (!selectedSportLocationId.value){
        return;
      }

      try {
        const response = await portalFilesApi.portalFilesGetPortalFile(
          selectedSportLocationId.value,
          fileName,
          {
            responseType: "arraybuffer",
          }
        );
        downloadDocument(response.data, response.headers);
      } catch {
        return
      //   snackbarColor.value = "error";
      //   snackbarText.value = i18n.t("subscription-request.download-error");
      //   snackBar.value = true;
      }
    }

    function getFileIcon(mimeType: string){
      if (mimeType === "application/pdf") {
        return "mdi-file-pdf-box"
      }
      else if (mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return "mdi-file-word-box"
      }
      else if (mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return "mdi-file-excel-box"
      }

      return "mdi-download-box"
    }

    function getIconByMimeType(mimeType: string | null | undefined) {
      if (!mimeType) return "mdi-download-box";
      
      switch (mimeType) {
        case "application/pdf":
          return "mdi-file-pdf";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "mdi-file-excel";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-word";
        case "image/png":
        case "image/jpeg":
        case "image/tiff":
        case "image/bmp":
          return "mdi-file-image";
        default:
          return "mdi-download-box";
      }
    }

    onMounted(async () => {
      // If all sportlocations are selected while in this screen redirect to dashboard.
      if (
        selectedSportLocationId.value &&
        selectedSportLocationId.value === -1
      ) {
        root?.$router.push({ name: "dashboard" });
      }
      fetchSportLocationInfo();
      fetchPortalFiles();
    });

    watch(selectedSportLocationId, () => {
      fetchSportLocationInfo();
    });

    return {
      portalFiles,
      selectedSportLocationInfo,
      service,
      state,
      bytesToSize,
      getFileIcon,
      getIconByMimeType,
      onDownloadPortalFile,
      send,
      toLocalDateTimeString,
    };
  },
});
export default Component;
</script>

<style lang="scss" scoped>
/* grid of 4 columns */
.portalfiles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;

  @media only screen and (min-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    &__title {
      font-size: 1rem;
      line-height: 1;
      align-items: center;
    }
  }
}
</style>